<template>
  <div class="main-content d-flex flex-column" style="height: 100vh">
    <div class="dashboard-banner position-relative">
      <div class="d-flex justify-content-center align-items-end" style="padding-bottom: 10vh !important;">
        <h4 class="mt-5 mx-4 text-center text-primary">{{ $t("transfer_record") }}</h4>
      </div>
        <div class="appBar">
          <a @click="$router.go(-1)">
            <i class="fa fa-chevron-left"></i>
          </a>
      </div>
     
    </div>

    <div
      class="mainpage pt-4 py-2 flex-grow-1"
      style="
        background: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        position: relative;
        overflow-y: scroll;
      "
    >
      <div
        v-if="currentPage > lastPage && dataList.length == 0"
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      >
        <p>No Data</p>
      </div>
      <div
        v-else-if="dataList.length == 0"
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      >
        <p>{{ $t("loading...") }}</p>
      </div>
      

      <div v-for="item in dataList" :key="item.id">
        <div class="px-3 py-3" @click="goDetails(item)">
          <b-row align-v="center">
            <b-col cols="6">
             
              <p class="mb-0 text-12 font-weight-bold"
                style="line-height: 18px">
                {{ $t("transfer_to") }} {{ $t(item.to_user.username) }}
              </p>
              <span class="text-10" style="line-height: 10px; letter-spacing: normal; color: rgb(127,127,127);">
                <div class="mr-2" style="display: inline-block;">
                  <img src="../../../assets/images/latrader_bonus/calender.png" alt="Image">
                  {{ getDate(item.created_at) }}
                </div>
                <div style="display: inline-block;">
                  <img src="../../../assets/images/latrader_bonus/clock.png" alt="Image">
                  {{ getTime(item.created_at) }}
                </div>
                </span>
            </b-col>
            <b-col cols="6" class="text-right">
               <p
                class="font-weight-bolder mb-0 text-16"
                style="line-height: 24px;"
              >
                {{ item.current }} USD
              </p>
         
            </b-col>
          </b-row>
        </div>
        <div
          class="mx-3 my-1"
          style="height: 1px; background-color: gray"
        ></div>
      </div>
      <b-button
        v-if="currentPage <= lastPage && dataList.length != 0"
        class="mx-auto submit_button mb-5"
        variant="outline-secondary"
        @click="getRecord"
        >{{ isLoading ? $t("loading...") : $t("load_more") }}</b-button
      >
    </div>
    <Dialog ref="msg"></Dialog>
  </div>
</template>

<script>
import { getTransferRecord } from "../../../system/api/api";
import { handleError } from "../../../system/handleRes";
import Dialog from "../../../components/dialog.vue";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["lang"]),
  },
  components: {
    Dialog,
  },
  data() {
    return {
      isLoading: false,
      dataList: [],
      currentPage: 1,
      lastPage: 1,
      site: ["unactive", "active", "end"],
    };
  },
  props: ["success"],
  methods: {
    getDate(dateTime) {
      const date = new Date(dateTime);
      const datePart = date.toLocaleDateString('en-US');
      return datePart;
    },
    getTime(dateTime) {
      const date = new Date(dateTime);
      const timePart = date.toLocaleTimeString('en-US', { hour12: false });
      return timePart;
    },
    getRecord() {
      var result = getTransferRecord(this.currentPage);
      var self = this;
      self.isLoading = true;

      result
        .then(function (value) {
          var dataList = value.data.data.data;
          console.log(dataList);
          self.currentPage += 1;
          self.lastPage = value.data.data.last_page;
          for (let i = 0; i < dataList.length; i++) {
            self.dataList.push(dataList[i]);
          }
          self.isLoading = false;
        })
        .catch(function (error) {
          self.isLoading = false;
          self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
        });
    },
  },
  created() {
    this.getRecord();
  },
};
</script>

<style scoped>
.main-content {
  max-width: 420px;
  margin: auto;
}
</style>